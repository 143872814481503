.UnityFrame {
    width: 80%;
    background-color: lightgray;
}

.GameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Game {
    position: relative;
    width: 100%;
}


.GameStack {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GameBottomRow {
    display: grid;
    width: fit-content;
    width: 80%;
}

.FullscreenButton {
    display: flex;
    flex-wrap: nowrap;
    justify-self: end;
    align-items: center;
}

.FullscreenImage {
    width: 2em;
}

.FullscreenText {
    margin-left: 0.2em;
    font-size: medium;
}

.LoadingOverlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

#ProgressBarEmpty {
    width: 141px;
    height: 18px;
    margin-top: 10px;
    margin-left: 6.5px;
}

#ProgressBarFull {
    width: 0%;
    height: 18px;
    margin-top: 10px;
}

#DownloadText {
    font-weight: bold;
}