.Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    justify-items: center;
    margin: 0 auto;
    gap: 0.4em;
}


.Form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.SubmitButton {
    margin-top: 0.4em;
    display: flex;
    align-self: flex-end;
    background-color: #0d6efd;
    border-color: #023889;
}

.SubmitButton:disabled {
    background-color: #66666677;
    color: #2e2e2e79;
    border-color: #00000079;
} 

.centerElement{
    display: flex;
    justify-content: center;
}