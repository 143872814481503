.ReturningUserContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    justify-items: center;
    margin: 0 auto;
    gap: 0.4em;
}

.ReturningUserForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.SubmitButton {
    margin-top: 0.4em;
    display: flex;
    align-self: flex-end;
}