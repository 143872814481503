
.SlabCarousel {
    width: 100%;
}

.SlabCarouselImage {
    height: auto;
    width: 100%;
}


.CarouselItemContainer {
    display: relative;
}

.Copyright 
{
    font-size: 11px;
    color: #243036;
    background-color: #79A0B1BB;
    padding: 0.1em 0.5em;
}

.CopyrightOverlay {
    position: absolute;
    right: 10px;
    bottom: 90px;
}

.SlabCarouselCaption
{
    color: white;
}

.Rect {
    background-color: #79A0B1 ;
    height: 100px;
}

.FullscreenModal .modal-dialog .modal-content
{
    background-color: #24303644;
}

.ModalFullscreenBody 
{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.ModalContainer 
{
    display: inline-block;
    position: relative;
    margin: auto;
}

.ModalFullscreenImage 
{
    max-width: 100vw;
    max-height: 95vh;
    width: 100%;
    object-fit: contain;
    margin: auto;
}

.ModalCopyrightOverlay {
    position: absolute;
    right: 10px;
    bottom: 5px;
}