.Welcome {
    width: 100%;
    display: flex;
}

.WelcomeHeader {
    display: flex;
}

.WelcomeStack {
    margin: 0 auto;
    width: 40%;
    align-items: center;
    max-width: 1080px;
    padding: 0 3em;
}

.WelcomeMarkdown > p {
    text-align: left;
}

.SlabImageContainer {
    /* background-color: #ffe4c4; */
    padding: 0.2em;
    position: relative;
    cursor: pointer;
    display: inline-block;
    background-color: #ffe4c4;
    border-color: #ffe4c4;
    border: 0.2em;
}

.SlabImage {
    max-width: min(200px, 40vw);
    max-height: 20vh;
}

.Overlay {
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    width:100%;
    height:100%;
    /*background-color:rgba(0,0,0,0.5); /* or use transparent pixel.gif like below */
}

.SlabOverlayText {
    margin-left: 0.2em;
    margin-right: 0.2em;
    background-color: rgba(0,0,0,0.5);
    color: bisque;
}

.FooterLogoContainer {

}

.FooterLogo {
    max-height: 40px;
    object-fit: contain;
    width: auto;
    height: auto;
    margin: 5px;
}