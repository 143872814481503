.App-header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 1em;
}

.Header {
    background-color: transparent;
    border-color: transparent;
}

.HeaderText {
    color: white;
}

.SideLogo {
    max-height: 40px;
    object-fit: contain;
    width: auto;
    height: auto;
    margin: 5px;
}

.SideLogoW {
    max-width: 160px;
    max-height: 80px;
    object-fit: contain;
    width: auto;
    height: auto;
    margin: 5px;
}

.SideLogoContainer {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
}

.SideContact {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    font-size: small;
}

.ContactLink {
    color: rgb(128, 128, 128); 
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

